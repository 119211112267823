// ContactForm.tsx
import React, { useState, FormEvent } from 'react'
import { css } from '../../../styled-system/css'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { useApi } from '../../hooks/useApi'
import { HeadFC, Link } from 'gatsby'
import { Button } from '../../components/photographie/Button'
import SEO from '../../components/SEO'

const ContactForm = () => {
  const { sendEmail } = useApi()
  const [subControl, setSubControl] = useState(false)
  const [message, setMessage] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [isSending, setIsSending] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    type_seance: ''
  })
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    type_seance: ''
  })

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target

    // Vérifiez que le champ "name" ne contient que des lettres
    if (name === 'name' && !/^[a-zA-Z\s]*$/.test(value)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: 'Veuillez entrer uniquement du texte pour le nom.',
      }))

      setTimeout(() => {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          name: '',
        }))
      }, 2000)
      return
    }

    // Vérifiez que le champ "phone" ne contient que des chiffres et éventuellement le signe "+"
    if (name === 'phone' && !/^\+?[0-9]*$/.test(value)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: 'Veuillez entrer uniquement des chiffres',
      }))

      setTimeout(() => {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          phone: '',
        }))
      }, 2000)
      return
    }

    // Vérifiez que le champ "email" contient un "@" et un "."

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleBlur = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target

    // Vérifiez que le champ "email" contient un "@" et un "."
    if (name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Veuillez entrer une adresse e-mail valide.',
      }))

      setTimeout(() => {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: '',
        }))
      }, 6000)
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      setMessage('Veuillez remplir tous les champs requis');
      setSubControl(true);
      return;
    }

    // Éviter les envois multiples
    if (isSending) return;

    setIsSending(true);
    try {
      await sendEmail(formData);
      setMessage(
        'Le mail a bien été envoyé, nous vous recontacterons dans les plus brefs délais.'
      );
      setFormSubmitted(true);
      setSubControl(false);
    } catch (error) {
      console.log(error)
      setMessage("Problème lors de l'envoi du message.");
    } finally {
      setIsSending(false);
    }
  };

  const validateForm = () => {
    return Object.values(formData).every((value) => value.trim() !== '')
  }
  return (
    <HeaderAndFooterLayout>
      {!formSubmitted && (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            maxWidth: '600px',
            margin: '100px auto 0',
            '@media (max-width: 768px)': {
              padding: '10px',
            },
          })}
        >
          <h1
            className={css({
              fontSize: '2rem',
              marginBottom: '20px',
              textAlign: 'center',
            })}
          >
            Contactez votre photographe spécialisé
          </h1>
          <form
            onSubmit={handleSubmit}
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              width: '100%',
            })}
            noValidate
          >
            <div className={css({ display: 'flex', flexDirection: 'column' })}>
              <label className={css({ marginBottom: '5px' })} htmlFor="name">
                Votre nom*
              </label>
              <input
                className={css({
                  padding: '0.625rem',
                  borderWidth: '0.1rem',
                  borderStyle: 'solid',
                  borderColor: '#D9C58B',
                  borderRadius: '0.3rem',
                  transition:
                    'border-color 1s ease-in-out, border-width 1s ease-in-out',
                  _focus: {
                    borderColor: '#d9bc6a',
                    borderWidth: '0.175rem',
                    outline: 'none',
                  },
                })}
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Nom"
              />
              {formErrors.name && (
                <p style={{ color: 'red' }}>{formErrors.name}</p>
              )}
              {subControl && formData.name === '' && (
                <span className={css({ color: 'red' })}>
                  Ce champ est obligatoire !
                </span>
              )}
            </div>

            <div className={css({ display: 'flex', flexDirection: 'column' })}>
              <label className={css({ marginBottom: '5px' })} htmlFor="email">
                Votre e-mail*
              </label>
              <input
                className={css({
                  padding: '0.625rem',
                  borderWidth: '0.1rem',
                  borderStyle: 'solid',
                  borderColor: '#D9C58B',
                  borderRadius: '0.3rem',
                  transition:
                    'border-color 1s ease-in-out, border-width 1s ease-in-out',
                  _focus: {
                    borderColor: '#d9bc6a',
                    borderWidth: '0.175rem',
                    outline: 'none',
                  },
                })}
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                placeholder="E-mail"
              />
              {formErrors.email && (
                <div className="error-message">
                  <p style={{ color: 'red' }}>{formErrors.email}</p>
                </div>
              )}
              {subControl && formData.email === '' && (
                <span className={css({ color: 'red' })}>
                  Ce champ est obligatoire !
                </span>
              )}
            </div>

            <div className={css({ display: 'flex', flexDirection: 'column' })}>
              <label className={css({ marginBottom: '5px' })} htmlFor="phone">
                Votre numéro de téléphone*
              </label>
              <input
                className={css({
                  padding: '0.625rem',
                  borderWidth: '0.1rem',
                  borderStyle: 'solid',
                  borderColor: '#D9C58B',
                  borderRadius: '0.3rem',
                  transition:
                    'border-color 1s ease-in-out, border-width 1s ease-in-out',
                  _focus: {
                    borderColor: '#d9bc6a',
                    borderWidth: '0.175rem',
                    outline: 'none',
                  },
                })}
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Numéro de téléphone"
              />
              {formErrors.phone && (
                <p style={{ color: 'red' }}>{formErrors.phone}</p>
              )}
              {subControl && formData.phone === '' && (
                <span className={css({ color: 'red' })}>
                  Ce champ est obligatoire !
                </span>
              )}
            </div>

            <div className={css({ display: 'flex', flexDirection: 'column' })}>
              <label className={css({ marginBottom: '5px' })} htmlFor="message">
                Votre message*
              </label>
              <textarea
                className={css({
                  padding: '0.625rem',
                  borderWidth: '0.1rem',
                  borderStyle: 'solid',
                  borderColor: '#D9C58B',
                  borderRadius: '0.3rem',
                  fontSize: '1rem',
                  resize: 'none',
                  minHeight: '100px',
                  transition:
                    'border-color 1s ease-in-out, border-width 1s ease-in-out',
                  _focus: {
                    borderColor: '#d9bc6a',
                    borderWidth: '0.175rem',
                    outline: 'none',
                  },
                })}
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Message"
              />
              {subControl && formData.message === '' && (
                <span className={css({ color: 'red' })}>
                  Ce champ est obligatoire !
                </span>
              )}
            </div>

            <div className={css({ display: 'flex', flexDirection: 'column' })}>
              <label className={css({ marginBottom: '5px' })} htmlFor="type">Type de séance*</label>
              <select
                className={css({
                  padding: '0.625rem',
                  borderWidth: '0.1rem',
                  borderStyle: 'solid',
                  borderColor: '#D9C58B',
                  borderRadius: '0.3rem',
                  transition: 'border-color 1s ease-in-out, border-width 1s ease-in-out',
                  _focus: { borderColor: '#d9bc6a', borderWidth: '0.175rem', outline: 'none', },
                })}
                id="type"
                name="type_seance"
                value={formData.type_seance}
                onChange={handleChange}
                required
              >
                <option value="" disabled>-- Sélectionnez le type de séance/reportage photo --</option>
                <option value="anniversaire">Anniversaire</option>
                <option value="baptème">Baptème</option>
                <option value="bébé">Bébé</option>
                <option value="couple">Couple</option>
                <option value="famille">Famille</option>
                <option value="grossesse">Grossesse</option>
                <option value="mariage">Mariage</option>
                <option value="naissance/nouveau né">Naissance/Nouveau-né</option>
                <option value="noël">Noël</option>
                <option value="pâques">Pâques</option>
                <option value="portrait pro/corporate">Portrait Pro/Corporate</option>
              </select>
              {subControl && formData.type === '' && (
                <span className={css({ color: 'type_seance' })}>Ce champ est obligatoire !</span>
              )}
            </div>
            <button
              className={`button3 ${css({
                padding: '10px',
                border: 'none',
                borderRadius: '4px',
                backgroundColor: '#D9C58B',
                color: '#fff',
                fontSize: '16px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#131b2b',
                },
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
              })}`}
              type="submit"
              
              onClick={() => setSubControl(true)}
            >
              Envoyer
            </button>
          </form>
          <span
            className={css({
              color: '#19213D',
              fontSize: '14px',
              marginTop: '10px',
              marginBottom: '10px',
              textAlign: 'center',
              '@media (max-width: 768px)': {
                fontSize: '1rem',
              },
            })}
          >
            * Le champ est obligatoire
          </span>
          <div
            className={css({
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            })}
          >
            {message && (
              <p
                className={css({
                  color: 'red',
                  fontSize: '1.25rem',
                  marginBottom: '1rem',
                })}
              >
                {message}
              </p>
            )}
          </div>
        </div>
      )}
      {formSubmitted && (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            maxWidth: '70rem',
            margin: '100px auto 0',
            '@media (max-width: 768px)': {
              padding: '10px',
            },
            alignItems: 'center',
          })}
        >
          <p
            className={css({
              fontSize: '3rem',
              marginBottom: '7rem',
              textAlign: 'center',
              color: '#000',
              fontWeight: 'bold',
            })}
          >
            Votre message a bien été envoyé !
          </p>
          <div
            className={css({
              alignItems: 'center',
              marginBottom: '7rem',
            })}
          >
            <span
              className={css({
                display: 'block',
                textAlign: 'center',
                fontFamily: 'Lato',
                fontSize: '1.5rem',
              })}
            >
              Merci {formData.name}, nous avons bien reçu votre message.
            </span>
            <span
              className={css({
                display: 'block',
                textAlign: 'center',
                fontFamily: 'Lato',
                fontSize: '1.5rem',
                marginBottom: '0.5rem',
              })}
            >
              Nous étudions votre demande et nous reviendrons vers vous dans les
              plus brefs délais.
            </span>
          </div>
          <div
            className={css({
              marginBottom: '7rem',
              padding: '1rem',
            })}
          >
            <Link to="/photographie/">
              <Button variants={{ format: 'normal', visual: 'digit' }}>
                <span
                  className={css({
                    fontFamily: 'Exo2',
                    color: '#000000',
                    fontSize: '1rem',
                    textAlign: 'center',
                  })}
                >
                  Retour à l&apos;Accueil
                </span>
              </Button>
            </Link>
          </div>
        </div>
      )}
    </HeaderAndFooterLayout>
  )
}

export default ContactForm

export const Head: HeadFC = () => (
  <SEO
    title="Contact - Digit Universe photographie"
    description="Contacter Digit Universe photographie"
    robotsContent="noindex, nofollow"
  />
)
